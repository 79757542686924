import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Index.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/project',
        name: 'Project',
        component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue')
    },
    {
        path: '/project-details',
        name: 'ProjectDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/ProjectDetails.vue')
    },
    {
        path: '/nolark',
        name: 'NolarkDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/NolarkDetails')
    },
    {
        path: '/siomag-3',
        name: 'SIOMAGDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/SIOMAGDetails')
    },
    {
        path: '/patrimoine',
        name: 'PatrimoineDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/PatrimoineDetails')
    },
    {
        path: '/wifisio',
        name: 'WifiSIODetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/WifiSIODetails')
    },
    {
        path: '/gsb',
        name: 'GSBDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/GSBDetails')
    },
    {
        path: '/mon-portfolio',
        name: 'MonPortfolioDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/MonPortfolioDetails')
    },
    {
        path: '/evoliz',
        name: 'EvolizDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/EvolizDetails')
    },
    {
        path: '/maison-des-ligues',
        name: 'MaisonDesLiguesDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/MaisonDesLiguesDetails')
    },
    {
        path: '/kairos',
        name: 'KairosDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/KairosDetails')
    },
    {
        path: '/voyages-pour-tous',
        name: 'VoyagesPourTousDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/VoyagesPourTousDetails')
    },
    {
        path: '/transvar',
        name: 'TransVarDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/TransVarDetails')
    },
    {
        path: '/morphy',
        name: 'MorphyDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/MorphyDetails')
    },
    {
        path: '/ggj',
        name: 'GGJDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/GGJDetails')
    },
    {
        path: '/symfony-cnam',
        name: 'SymfonyCnamDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/SymfonyCnamDetails')
    },
    {
        path: '/slowlife',
        name: 'SlowLifeDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/SlowLifeDetails')
    },
    {
        path: '/vue-portfolio',
        name: 'VuePortfolioDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/VuePortfolioDetails')
    },
    {
        path: '/opera',
        name: 'OperaDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/OperaDetails')
    },
    {
        path: '/lifecycle',
        name: 'LifeCycleDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/LifeCycleDetails')
    },
    {
        path: '/zaunesport',
        name: 'ZaunEsportDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/ZaunEsportDetails')
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue')
    },
    {
        path: '/blog/:slug',
        name: 'BlogDetails',
        component: () => import(/* webpackChunkName: "blog-details" */ '../views/BlogDetails.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
